<!-- Title -->
<h3 class="ui-portal-title">Members</h3>

<!-- Invitation form -->
<div class="ui-form">
  <div class="control required">
    <label>Invite to startup</label>
    <div class="ui-flex">
      <!-- Email -->
      <input type="text" placeholder="user@email.com" [(ngModel)]="email" required>
      <button class="btn-primary" (click)="sendInvitation()">Send invitation</button>
    </div>
  </div>
</div>

<!-- Grid -->
<ui-grid
  [columns]="columnsDef"
  [data]="model.data"
  [config]="config"
  [noWrap]="true"
  (onAction)="onGridAction($event)"
>
</ui-grid>

